<template>
    <div class='titlenav'>
        <div class="left">
            <div class="line"></div>
            <div class="title">{{ text }}</div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'titlenav',
  components: {

  },
  data () {
    return {
    }
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    pageUrl: {
      type: String,
      default: ''
    },
    goShow: {
      type: Boolean,
      default: true
    }
  },
  created () {

  },
  mounted () {

  },
  methods: {

  },
  watch: {

  },
  computed: {

  }
}
</script>
<style lang='scss' scoped>
.titlenav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .left {
        display: flex;
        align-items: center;

        .line {
            width: 5px;
            height: 15px;
            background: #FF9580;
            border-radius: 15px;
            margin-right: 8px;
        }

        .title {
            color: #898989;
            font-size: .22rem;
            line-height: .22rem;
        }
    }
}
</style>
